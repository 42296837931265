<template lang="pug">
a.cta(:class='position,variant' :style='inlineStyle' :href='link' :target='target')
  img(:src='logoSrc' alt='samedi')
  strong Termin online buchen
</template>

<script>
import { lightOrDark } from "@/utils/color"
export default {
  props: {
    position: String,
    height: Number,
    link: String,
    target: String,
    variant: String,
    color: String,
  },
  computed: {
    inlineStyle() {
      let style = `top: ${this.height}%;`
      if(this.variant == 'color') {
        style += `background-color:${this.color};`
        if(lightOrDark(this.color) == 'dark') {
          style += 'color:#ffffff;'
        } else {
          style += 'color:#104080;'
        }
      }
      return style
    },
    logoSrc() {
      let logo = 'blue'
      if(this.variant == 'light') {
        logo = 'skyblue'
      } else if (this.variant == 'dark' || lightOrDark(this.color) == 'dark') {
        logo = 'simple-rounded-white'
      }
      return `/samedi-logo-${logo}.svg`
    }
  }
}
</script>

<style lang="sass" scoped>
.cta
  position: fixed
  top: 20%
  display: block
  text-align: left
  font-size: 14px
  overflow: hidden
  width: 55px
  text-decoration: none
  line-height: 18px
  padding: 16px 8px
  font-weight: bold
  z-index: 999
  &.right
    right: 0
    border-radius: 8px 0 0 8px
  &.left
    left: 0
    border-radius: 0 8px 8px 0
  &.light
    background-color: #ffffff
    color: #0067b2
    border: 1px solid #e6e6e6
  &.dark
    background-color: #104080
    color: #ffffff

  // &:hover
  //   color: #00a3e2
  img
    width: 55px
    margin-bottom: 10px


</style>
