<template lang="pug">
SamediCta(:position='position' :height='height' :link='link' :target='target' :variant='variant' :color='color')

.configuration
  h2 1. Variante auswählen
  .variant
    input#light(type='radio' name='variant' v-model='variant' value='light')
    label(for='light') hell (PAPP-Logo)
    input#dark(type='radio' name='variant' v-model='variant' value='dark')
    label(for='dark') dunkel (samedi Logo)
    input#color(type='radio' name='variant' v-model='variant' value='color')
    label(for='color') Individuelle Farbe
    ColorPicker(:color="color" @color-change="updateColor" :visible-formats="['rgb', 'hex']" default-format="hex" v-if="variant == 'color'")
      template(v-slot:format-switch-button)
        svg(aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 16 15')
          path(d='M8 15l5-5-1-1-4 2-4-2-1 1zm4-9l1-1-5-5-5 5 1 1 4-2z' fill='currentColor')
      template(v-slot:copy-button)
        svg(aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15')
          path(d='M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z' fill='currentColor')
          path(d='M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z' fill='currentColor')
  h2 2. Position festlegen:
  .position
    input#positionLeft(type='radio' name='positionLeft' v-model='position' value='left')
    label(for='positionLeft') links
    input#positionRight(type='radio' name='positionRight' v-model='position' value='right')
    label(for='positionRight') rechts
  h2 3. Höhe bestimmen:
  .height
    input(type='range' name='height' min='0' max='85' v-model='height')
    label(for='height') in %
  h2 4. Link konfigurieren:
  label URL: &nbsp;
  input(type='text' v-model='link')
  div
    input#blank(type='radio' name='blank' v-model='target' value='_blank')
    label(for='blank') In neuem Tab öffnen
    input#self(type='radio' name='self' v-model='target' value='_self')
    label(for='self') Im gleichen Fenster/Tab öffnen

h2 5. Code kopieren und in die Webseite einfügen:
CodeBlock(:position='position' :height='height' :link='link' :target='target' :variant='variant' :color='color')

</template>

<script>
import SamediCta from './SamediCta.vue'
import CodeBlock from './CodeBlock.vue'
import { ColorPicker } from 'vue-accessible-color-picker'
export default {
  components: {
    SamediCta,
    CodeBlock,
    ColorPicker,
  },
  data: function() {
    return {
      variant: 'dark',
      position: 'right',
      height: 20,
      link: '#',
      target: '_blank',
      color: '#c0ffee'
    }
  },
  methods: {
    updateColor (eventData) {
      this.color = eventData.cssColor.substring(0, 7)
    }
  },
}
</script>

<style lang="sass" scoped>
.vacp-color-picker
  margin: auto
.variant::v-deep .vacp-range-input-label--alpha, .variant::v-deep #color-picker-color-rgb-a
  display: none
</style>
